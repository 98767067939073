var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Attendance List")]), _c('Button', {
    attrs: {
      "buttonText": "Download",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.downloadFile();
      }
    }
  })], 1), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6"
  }, [_vm._l(_vm.filters, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "p-0"
    }, [item.field != 'btn' ? _c('div', {
      staticClass: "m-input-icon m-input-icon--left"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Search By " + _vm._s(item.label))]), item.field === 'startDate' ? _c('div', {}, [_c('TextField', {
      attrs: {
        "type": "date",
        "enterKeyAction": _vm.onSearch,
        "borderEnabled": "",
        "placeholder": "Select Date"
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1) : _vm._e(), item.field === 'endDate' ? _c('div', {}, [_c('TextField', {
      attrs: {
        "type": "date",
        "enterKeyAction": _vm.onSearch,
        "borderEnabled": "",
        "placeholder": "Select Date"
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1) : _vm._e(), item.field === 'q' ? _c('div', {
      staticClass: "relative"
    }, [_c('TextField', {
      attrs: {
        "type": "text",
        "enterKeyAction": _vm.onSearch,
        "withIcon": "",
        "borderEnabled": "",
        "placeholder": item.label
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    }), _c('div', {
      staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
    }, [_c('Search')], 1)], 1) : _vm._e()]) : _vm._e()]);
  }), _c('div', {
    staticClass: "flex justify-start mt-7 mb-6"
  }, [_c('p', {
    staticClass: "text-yellow flex items-center gap-1 cursor-pointer font-semibold",
    on: {
      "click": function click($event) {
        return _vm.resetFilter();
      }
    }
  }, [_c('Trash'), _vm._v("Reset Filter")], 1)])], 2), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'checkIn' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field === 'checkOut' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }