<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Attendance List</h1>
        <Button buttonText="Download" type="primary" @action="downloadFile()" />
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6">
          <div class="p-0" v-for="item in filters" v-bind:key="item.id">
            <div v-if="item.field != 'btn'" class="m-input-icon m-input-icon--left">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search By {{ item.label }}</label>
              <div class="" v-if="item.field === 'startDate'">
                <TextField type="date" :enterKeyAction="onSearch" v-model="options[item.field]" borderEnabled placeholder="Select Date" />
              </div>
              <div class="" v-if="item.field === 'endDate'">
                <TextField type="date" :enterKeyAction="onSearch" v-model="options[item.field]" borderEnabled placeholder="Select Date" />
              </div>
              <div class="relative" v-if="item.field === 'q'">
                <TextField type="text" :enterKeyAction="onSearch" withIcon borderEnabled v-model="options[item.field]" :placeholder="item.label" />
                <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                  <Search />
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-start mt-7 mb-6">
            <p class="text-yellow flex items-center gap-1 cursor-pointer font-semibold" @click="resetFilter()"><Trash />Reset Filter</p>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalRecords"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'checkIn'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'checkOut'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Kelas',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Trash: () => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')
  },
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      options: {
        limit: 11,
        page: 0,
        q: '',
        startDate: '',
        endDate: ''
      },
      isFetching: false,
      isSearching: false,
      currentKeyword: '',
      attendanceSelected: {},
      attendanceListResult: [],
      totalClass: 0,
      masterId: '',
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      isKeywordSameTwice: false,
      existingAttendance: ''
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          customerId: this.customerId
        },
        ...this.options
      })

      this.$store
        .dispatch('attendance/GET_LIST_ATTENDANCE', {
          masterType: this.$route.meta.name,
          params: paramsTemp
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
          this.hideLoading()
        })
    },

    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage - 1
      let paramsTemp = queryString.stringify({
        ...{
          limit: params.currentPerPage,
          page: params.currentPage,
          size: params.currentPerPage,
          customerId: this.customerId
        },
        ...this.options
      })

      this.$store.dispatch('attendance/GET_LIST_ATTENDANCE', {
        masterType: this.$route.meta.name,
        params: paramsTemp
      })
    },

    onSearch() {
      this.options.page = 0
      let paramsTemp = queryString.stringify({
        ...{
          customerId: this.customerId
        },
        ...this.options
      })

      this.$store.dispatch('attendance/GET_LIST_ATTENDANCE', {
        masterType: this.$route.meta.name,
        params: paramsTemp
      })
    },

    resetFilter() {
      ;(this.options.q = ''), (this.options.startDate = ''), (this.options.endDate = ''), this.showLoading()
      this.initData()
    },
    downloadFile() {
      let baseUrl
      switch (process.env.NODE_ENV) {
        case 'production':
          baseUrl = 'https://prod-api-v4.g2academy.co'
          break
        case 'staging':
          baseUrl = 'https://staging-api-v4.g2academy.co'
          break
        case 'beta':
          baseUrl = 'https://dev-api-v4.g2academy.co'
          break
      }
      let paramsTemp = queryString.stringify({
        ...{
          customerId: this.customerId
        },
        ...this.options
      })
      const url = {
        production: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances/download?${paramsTemp}`,
        staging: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances/download?${paramsTemp}`,
        beta: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances/download?${paramsTemp}`
      }
      axios({
        // url: `${baseUrl}/new/hr/attendances/download?${paramsTemp}`, // File URL Goes Here
        url: url[process.env.NODE_ENV],
        method: 'GET',
        responseType: 'blob'
      }).then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `data-karyawan.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
    },

    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    }
  },
  computed: {
    keyword() {
      return this.options.q || this.options.startDate || this.options.endDate
    },
    customerId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['attendance/list_attendance'] ? this.$store.getters['attendance/list_attendance'] : []
    },
    totalRecords: function () {
      return this.$store.getters['attendance/paginate'].total ? this.$store.getters['attendance/paginate'].total : 0
    },
    columns: function () {
      return [
        { field: 'id', label: 'Id' },
        { field: 'employeeName', label: 'Name' },
        { field: 'employeeEmail', label: 'Email' },
        { field: 'checkIn', label: 'Start' },
        { field: 'checkOut', label: 'Leave' }
      ]
    },
    filters: function () {
      return [
        { field: 'startDate', label: 'Start Date' },
        { field: 'endDate', label: 'End Date' },
        { field: 'q', label: 'Name' }
      ]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    }
  },
  created: function () {
    // this.initData()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    },
    keyword(value) {
      if (value === this.currentKeyword) {
        if (!this.isKeywordSameTwice) this.isSearching = true
      } else {
        this.attendanceSelected = {}
        this.isKeywordSameTwice = true
        this.isSearching = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
